import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeCarousel from "../components/home/home-carousel"
import { graphql } from "gatsby"
import ProjectsLayout from "../components/projects/projects-layout"

const ProjectsPage = ({ data }) => (
  <ProjectsLayout>
    <SEO title="Projects" />
    <HomeCarousel gallery={data.homepageData.acf.gallery} />
  </ProjectsLayout>
)

export default ProjectsPage

export const HomePageQuery = graphql`
  query homepageQuery {
    homepageData: wordpressPage(title: { eq: "Home" }) {
      acf {
        gallery {
          source_url
          localFile {
            childImageSharp {
              fluid(maxHeight: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`