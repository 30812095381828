import React, { Component } from "react"
import Carousel from "nuka-carousel"
import styled from "styled-components"
import Img from "gatsby-image"
import SEO from "../seo"

const Container = styled.section`
    height: calc(100vh - 96px);
    position: relative;
`
const ImageContainer = styled.div`
    margin: 0;
    width: 100%;
    height: calc(100vh - 96px);
    object-fit: cover;
`

class HomeCarousel extends Component {
  state = {}
  render() {
    const { gallery } = this.props
    var randomImage = gallery[Math.floor(Math.random() * gallery.length)]

    return (
      <Container>
            <SEO title="Projects" />
            <Carousel
                renderCenterLeftControls={() => null}
                renderCenterRightControls={() => null}
                renderBottomCenterControls={() => null}
                autoplay
                swiping={false}
                dragging={false}
                transitionMode="scroll"
            >
            <ImageContainer>
                <Img
                style={{
                    width: "100%",
                    height: "100%",
                    margin: 0,
                }}
                fadeIn
                alt="slideshow_image"
                objectFit="cover"
                fluid={randomImage.localFile.childImageSharp.fluid}
                />
            </ImageContainer>
            {/* <SlideImg src={randomImage.source_url} /> */}
            </Carousel>
      </Container>
    )
  }
}

export default HomeCarousel